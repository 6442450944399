import { User } from "../types";
import { api } from "./api";

type Credentials = {
  email: string;
  password: string;
};

type SignInResponse = {
  accessToken: string;
};

const authSlice = api.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getProfile: builder.query<User, void>({
      query: () => "/auth/profile",
    }),
    signWithGoogle: builder.query<string, void>({
      query: () => "/auth/google",
    }),
    signWithGoogleCallback: builder.query<string, string>({
      query: (code: string) => `/auth/google/callback?code=${code}`,
    }),
    signIn: builder.mutation<SignInResponse, Credentials>({
      query: (credentials) => ({
        url: `/auth/authenticate`,
        method: "POST",
        body: credentials,
      }),
    }),
  }),
});

export const {
  useSignInMutation,
  useLazySignWithGoogleQuery,
  useLazySignWithGoogleCallbackQuery,
  useLazyGetProfileQuery,
} = authSlice;
