export type Task = {
  title: string;
  user: {
    name: string;
  };
  createdAt: string;
  uuid: string;
  labels?: {
    taskId: string;
    labelId: string;
  }[];
};
