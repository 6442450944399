import { api } from "./api";

const tasksLabelsSlice = api.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getTasksLabels: builder.query<{ labelId: string }[], string>({
      query: (taskUUID: string) => `/tasks/${taskUUID}/labels`,
      providesTags: ["tasksLabels"],
    }),
    addTaskLabel: builder.mutation<void, { taskId: string; labelId: string }>({
      query: ({ taskId, labelId }) => ({
        url: `/tasks/${taskId}/labels/${labelId}`,
        method: "POST",
      }),
    }),
    removeTaskLabel: builder.mutation<
      void,
      { taskId: string; labelId: string }
    >({
      query: ({ taskId, labelId }) => ({
        url: `/tasks/${taskId}/labels/${labelId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useLazyGetTasksLabelsQuery,
  useAddTaskLabelMutation,
  useRemoveTaskLabelMutation,
} = tasksLabelsSlice;
