import { createSlice } from "@reduxjs/toolkit";

export interface AuthState {
  accessToken: string | null;
}

const initialState: AuthState = {
  accessToken: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, token) => {
      state.accessToken = token.payload;
    },
  },
});

export const { setToken } = authSlice.actions;

export default authSlice.reducer;
