import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../../store";

const publicEndpoints = ["signIn"];

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api",
    prepareHeaders: (headers, { getState, endpoint }) => {
      const token = (getState() as RootState).auth.accessToken;

      // If we have a token set in state, let's assume that we should be passing it.
      if (token && !publicEndpoints.includes(endpoint)) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ["tasksList", "auth", "labelsList", "tasksLabels"],
  endpoints: (_) => ({}),
});
