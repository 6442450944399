import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { RootState } from "../store";

type ProtectedProps = {
  children: JSX.Element[] | JSX.Element;
};

export function Protected({ children }: ProtectedProps) {
  const accessToken = useSelector((state: RootState) => state.auth.accessToken);

  if (!accessToken) {
    return <Navigate to="/auth/signin" />;
  }

  return <>{children}</>;
}
