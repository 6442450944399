import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  useLazySignWithGoogleCallbackQuery,
  useLazyGetProfileQuery,
} from "../core";
import { setToken } from "../core";
import { setUser } from "../core/reducers";

export function GoogleAuth() {
  const [searchParams] = useSearchParams();
  const [signWithGoogleCallback] = useLazySignWithGoogleCallbackQuery();
  const dispatch = useDispatch();
  const [getProfile] = useLazyGetProfileQuery();
  const navigate = useNavigate();

  const signWithGoogleRequest = async (): Promise<any> => {
    return signWithGoogleCallback(searchParams.get("code") as string).unwrap();
  };

  useEffect(() => {
    signWithGoogleRequest().then(async (result) => {
      dispatch(setToken(result.accessToken));
      const profile = await getProfile().unwrap();

      dispatch(setUser(profile));
      localStorage.setItem("accessToken", result.accessToken);
      localStorage.setItem("user", JSON.stringify(profile));
      navigate("/");
    });
  }, []);

  return "Authorizing";
}
