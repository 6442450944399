import { Task } from "../types";
import { api } from "./api";

type TaskPayload = {
  title: string;
  user: string;
  labels?: { taskId: string; labelId: string }[];
};

type TasksResponse = {
  payload: Task[];
  meta: {
    nextCursor?: string;
    totalCount: number;
  };
};

type TasksQueryParams = {
  cursor?: string;
  limit?: number;
};

type EditTaskPayload = {
  title: string;
};

const tasksSlice = api.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getTasks: builder.query<TasksResponse, TasksQueryParams>({
      merge: (currentCache, newItems) => {
        currentCache.payload.push(...newItems.payload);
        currentCache.meta = newItems.meta;
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      query: (queryParams: TasksQueryParams) => {
        const url = new URL("http://example.com");

        if (queryParams.limit) {
          url.searchParams.append("limit", String(queryParams.limit));
        }

        if (queryParams.cursor) {
          url.searchParams.append("cursor", String(queryParams.cursor));
        }

        return `/tasks${url.search}`;
      },
      providesTags: ["tasksList"],
    }),
    getTask: builder.query<Task, string>({
      query: (uuid) => `/tasks/${uuid}`,
    }),
    removeTask: builder.mutation<void, string>({
      query: (uuid) => ({
        url: `/tasks/${uuid}`,
        method: "DELETE",
      }),
    }),
    addTask: builder.mutation<void, TaskPayload>({
      query: (task) => ({
        url: `/tasks`,
        method: "POST",
        body: task,
      }),
      invalidatesTags: ["tasksList"],
    }),
    editTask: builder.mutation<void, { task: EditTaskPayload; uuid: string }>({
      query: ({ uuid, task }) => ({
        url: `/tasks/${uuid}`,
        method: "PUT",
        body: task,
      }),
      invalidatesTags: ["tasksList"],
    }),
  }),
});

export const {
  useGetTasksQuery,
  useLazyGetTasksQuery,
  useRemoveTaskMutation,
  useAddTaskMutation,
  useLazyGetTaskQuery,
  useEditTaskMutation,
  endpoints,
  reducer,
  reducerPath,
  util: { updateQueryData },
} = tasksSlice;
