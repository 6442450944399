import { useParams, useNavigate } from "react-router-dom";
import {
  Label,
  Task,
  useAddTaskLabelMutation,
  useEditTaskMutation,
  useLazyGetTaskQuery,
  useLazyGetTasksLabelsQuery,
  useRemoveTaskLabelMutation,
} from "../core";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { RootState } from "../store";

export function EditTask() {
  const { uuid } = useParams();
  const [task, setTask] = useState<Task>({} as Task);
  const [taskLabels, setTaskLabels] = useState<{ labelId: string }[]>([]);
  const navigate = useNavigate();
  const labels = useSelector((state: RootState) => state.labels);

  const [getTask] = useLazyGetTaskQuery();
  const [getTaskLabels] = useLazyGetTasksLabelsQuery();
  const [updateTask] = useEditTaskMutation();
  const [addTaskLabel] = useAddTaskLabelMutation();
  const [removeTaskLabel] = useRemoveTaskLabelMutation();

  useEffect(() => {
    async function fetchTask() {
      if (uuid) {
        const taskResponse = await getTask(uuid).unwrap();
        setTask(taskResponse);
        const taskLabels = await getTaskLabels(uuid).unwrap();
        setTaskLabels(taskLabels);
      }
    }

    fetchTask();
  }, [uuid]);

  return (
    <dialog id="my_modal_1" className="modal" open>
      <div className="modal-box modal-open">
        {task.title && (
          <input
            className="input w-full max-w-xs"
            onChange={(event) => {
              const newTask = { ...task, title: event.target.value };

              setTask(newTask);
            }}
            value={task.title}
          />
        )}
        <ul className="mt-4">
          {!labels?.length
            ? ""
            : labels.map((label: Label) => (
                <Label
                  color={label.color}
                  isActive={taskLabels.some(
                    (taskLabel) => taskLabel.labelId === (label.uuid as string)
                  )}
                  key={label.uuid}
                  className="bg-red-100 text-white relative rounded-box p-1 pl-4 mb-4"
                  onClick={async () => {
                    if (
                      taskLabels.some(
                        (taskLabel) =>
                          taskLabel.labelId === (label.uuid as string)
                      )
                    ) {
                      await removeTaskLabel({
                        taskId: uuid as string,
                        labelId: label.uuid as string,
                      });
                    } else {
                      await addTaskLabel({
                        taskId: uuid as string,
                        labelId: label.uuid as string,
                      });
                    }

                    const taskLabelsFetch = await getTaskLabels(
                      uuid as string
                    ).unwrap();
                    setTaskLabels(taskLabelsFetch);
                  }}
                >
                  {label.name}
                </Label>
              ))}
        </ul>
        <div className="modal-action">
          <form method="dialog">
            <button onClick={() => navigate(-1)} className="btn mr-4">
              Close
            </button>
            <button
              onClick={async () => {
                await updateTask({
                  uuid: uuid as string,
                  task: {
                    title: task.title,
                  },
                });

                navigate(-1);
              }}
              className="btn btn-accent"
            >
              Save
            </button>
          </form>
        </div>
      </div>
    </dialog>
  );
}

const Label = styled.li<{ color: string; isActive: boolean }>`
  background-color: ${(props) => props.color || "#1273de"};
  opacity: ${(props) => (props.isActive ? 1 : 0.5)};
  cursor: pointer;
`;
