import { useEffect, useState } from "react";
import {
  Label,
  Task,
  useAddTaskMutation,
  useGetTasksQuery,
  useRemoveTaskMutation,
  updateQueryData,
} from "../core";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import styled from "styled-components";

export function Tasks() {
  const [newTask, setNewTask] = useState("");
  const [query, setQuery] = useState({ limit: 5, cursor: "" });

  const { data, refetch } = useGetTasksQuery(query);
  const [removeTask] = useRemoveTaskMutation();
  const [addTask] = useAddTaskMutation();
  const navigate = useNavigate();
  const location = useLocation();
  const labels = useSelector((state: RootState) => state.labels);
  const dispatch = useDispatch<any>();

  useEffect(() => {
    if (location.pathname === "/tasks") {
      refetch();
    }

    return () => {
      dispatch(
        updateQueryData("getTasks", {}, (cachedTasks) => {
          cachedTasks.payload.splice(0);
          cachedTasks.meta.nextCursor = "";
        })
      );
    };
  }, [location.pathname]);

  return (
    <>
      <div className="flex gap-2 mb-10">
        <input
          value={newTask}
          type="text"
          className="input input-bordered input-primary input-sm w-full"
          placeholder="Enter new task here..."
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              addTask({
                title: newTask,
                user: "dddc644f-f8f0-42ad-a699-105e20e6ebfb",
              }).then(() => setNewTask(""));
            }
          }}
          onChange={(event) => setNewTask(event.target.value)}
        />
        <button
          onClick={() =>
            addTask({
              title: newTask,
              user: "dddc644f-f8f0-42ad-a699-105e20e6ebfb",
            }).then(() => setNewTask(""))
          }
          className="btn btn-primary btn-sm"
        >
          Add
        </button>
      </div>
      {!data?.payload?.length ? (
        "No tasks available"
      ) : (
        <>
          <table className="table table-xs">
            <thead>
              <tr>
                <th className="text-left">Title</th>
                <th className="text-left">Created At</th>
                <th className="text-left">Labels</th>
                <th className="text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              {data.payload.map((task: Task, index) => (
                <tr key={task.uuid}>
                  <td>{task.title}</td>
                  <td>{new Date(task.createdAt).toLocaleString()}</td>
                  <td>
                    {!labels?.length
                      ? ""
                      : labels
                          .filter((label: Label) =>
                            task.labels?.find(
                              (taskLabel) => taskLabel.labelId === label.uuid
                            )
                          )
                          .map((label: Label) => (
                            <Label
                              color={label.color}
                              isActive
                              key={label.uuid}
                              className="bg-red-100 text-white relative rounded-box p-1 pl-4 mb-4"
                            >
                              {label.name}
                            </Label>
                          ))}
                  </td>
                  <td>
                    <button
                      className="btn btn-error btn-sm mr-4"
                      onClick={async () => {
                        await removeTask(task.uuid).unwrap();
                        console.log("deleteing an item");

                        dispatch(
                          updateQueryData("getTasks", {}, (cachedTasks) => {
                            cachedTasks.payload.splice(index, 1);

                            setQuery({
                              limit: 1,
                              cursor:
                                cachedTasks.payload[
                                  cachedTasks.payload.length - 1
                                ].createdAt,
                            });
                          })
                        );
                      }}
                    >
                      Remove
                    </button>
                    <button
                      className="btn btn-info btn-sm"
                      onClick={() => {
                        navigate(`/tasks/${task.uuid}`);
                      }}
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {data?.meta.nextCursor &&
            data?.meta.totalCount > data?.payload.length && (
              <button
                className="btn w-full mt-4 btn-info btn-sm mr-4"
                onClick={() => {
                  setQuery({
                    limit: 5,
                    cursor: data?.meta.nextCursor as string,
                  });
                }}
              >
                Load More
              </button>
            )}
          <Outlet />
        </>
      )}
    </>
  );
}

const Label = styled.li<{ color: string; isActive: boolean }>`
  background-color: ${(props) => props.color || "#1273de"};
  opacity: ${(props) => (props.isActive ? 1 : 0.5)};
  cursor: pointer;
  width: max-content;
  display: inline-block;
  padding-right: 15px;
  margin-right: 5px;
`;
