import { createSlice } from "@reduxjs/toolkit";
import * as labelsSliceApi from "../apis/labels";

const initialState: any = [];

export const labelsSlice = createSlice({
  name: "labels",
  initialState,

  reducers: {
    setLabels: (_, labels) => {
      return labels.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      labelsSliceApi.endpoints.getLabels.matchFulfilled,
      (_, action) => {
        return action.payload;
      }
    );
  },
});

export const { setLabels } = labelsSlice.actions;

export default labelsSlice.reducer;
