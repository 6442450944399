import { createSlice } from "@reduxjs/toolkit";
import type { User } from "../types";

type UserState = User | null;

const initialState = null as UserState;

export const userSlice = createSlice({
  name: "user",
  initialState,

  reducers: {
    setUser: (_, user) => {
      return user.payload;
    },
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
