import { configureStore } from "@reduxjs/toolkit";
import { api } from "./core";
import {
  authReducer,
  labelsReducer,
  setToken,
  setUser,
  userReducer,
} from "./core";
import { router } from "./router";

const preloadedState = () => {
  const auth = {
    accessToken: localStorage.getItem("accessToken"),
  };

  const storageUser = localStorage.getItem("user");

  const user = storageUser ? JSON.parse(storageUser) : null;

  return {
    user,
    auth,
  };
};

const errorMiddleware = (store: any) => (next: any) => (action: any) => {
  if (action.type.endsWith("/rejected")) {
    const error = action.payload || action.error.message;

    if (error.status === 401) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("user");

      store.dispatch(setUser(null));
      store.dispatch(setToken(null));

      router.navigate("/auth/signin");
    }
  }

  return next(action);
};

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    auth: authReducer,
    user: userReducer,
    labels: labelsReducer,
  },
  preloadedState: preloadedState(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware).concat(errorMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
