import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useState } from "react";
import { setToken } from "../core";
import {
  useLazyGetProfileQuery,
  useLazySignWithGoogleQuery,
  useSignInMutation,
} from "../core";
import { setUser } from "../core/reducers";

export function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [authenticate] = useSignInMutation();
  const [getProfile] = useLazyGetProfileQuery();
  const [signWithGoogle] = useLazySignWithGoogleQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const authenticateCallback = async () => {
    const result = await authenticate({ email, password })
      .unwrap()
      .catch((error: any) => {
        console.log(error);
      });

    if (result) {
      dispatch(setToken(result.accessToken));
      const profile = await getProfile().unwrap();

      dispatch(setUser(profile));
      localStorage.setItem("accessToken", result.accessToken);
      localStorage.setItem("user", JSON.stringify(profile));

      navigate("/");
    }
  };

  return (
    <div className="mx-auto lg:w-3/12 mobile:w-8/12 mobile:mt-40 mt-300 p-3">
      <h1 className="text-center mb-8">My Mate</h1>
      <label className="input input-bordered flex items-center gap-2 mb-8">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          fill="currentColor"
          className="w-4 h-4 opacity-70"
        >
          <path d="M2.5 3A1.5 1.5 0 0 0 1 4.5v.793c.026.009.051.02.076.032L7.674 8.51c.206.1.446.1.652 0l6.598-3.185A.755.755 0 0 1 15 5.293V4.5A1.5 1.5 0 0 0 13.5 3h-11Z" />
          <path d="M15 6.954 8.978 9.86a2.25 2.25 0 0 1-1.956 0L1 6.954V11.5A1.5 1.5 0 0 0 2.5 13h11a1.5 1.5 0 0 0 1.5-1.5V6.954Z" />
        </svg>
        <input
          type="text"
          onChange={(event) => setEmail(event.target.value)}
          className="grow"
          value={email}
          placeholder="Email"
        />
      </label>
      <label className="input input-bordered flex items-center gap-2 mb-8">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          fill="currentColor"
          className="w-4 h-4 opacity-70"
        >
          <path
            fillRule="evenodd"
            d="M14 6a4 4 0 0 1-4.899 3.899l-1.955 1.955a.5.5 0 0 1-.353.146H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2.293a.5.5 0 0 1 .146-.353l3.955-3.955A4 4 0 1 1 14 6Zm-4-2a.75.75 0 0 0 0 1.5.5.5 0 0 1 .5.5.75.75 0 0 0 1.5 0 2 2 0 0 0-2-2Z"
            clipRule="evenodd"
          />
        </svg>
        <input
          type="password"
          value={password}
          onKeyPress={async (event) => {
            if (event.key === "Enter") {
              await authenticateCallback();
            }
          }}
          onChange={(event) => setPassword(event.target.value)}
          className="grow"
        />
      </label>
      <button
        className="btn btn-primary w-full mb-4"
        onClick={authenticateCallback}
      >
        Sign In
      </button>
      <button
        className="btn btn-primary w-full"
        onClick={async () => {
          const redirectUrl = await signWithGoogle().unwrap();

          window.location.href = redirectUrl;
        }}
      >
        Login with Google
      </button>
    </div>
  );
}
